<template>
	<div class="home">
		<SearchPage/>
	</div>
</template>

<script>
import SearchPage from './SearchPage.vue'

export default {
	name: 'HomeView',
	components: {
		SearchPage
	}
}
</script>
<style scoped lang="scss">

</style>