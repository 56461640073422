<template>
	<form action="" role="search" novalidate="" class="ais-SearchBox-form">
		<!-- :value/@input allows us to pass v-model to the component in v2 --><!-- :modelValue/@update:modelValue allows us to pass v-model to the component in v3 --><input
		type="search"
		v-model="query"
		autocorrect="off"
		autocapitalize="off"
		autocomplete="on"
		spellcheck="false"
		required=""
		maxlength="512"
		aria-label="Search"
		placeholder="Cherchez un prénom, une couleur ou un mot-clé"
		class="ais-SearchBox-input"
		/>
		<img class="search-icon" src="@/assets/icon-search.svg"/>
	<button type="reset" title="Clear" class="ais-SearchBox-reset" hidden="">
		<svg
			role="img"
			xmlns="http://www.w3.org/2000/svg"
			width="1em"
			height="1em"
			viewBox="0 0 20 20"
			class="ais-SearchBox-resetIcon"
		>
			<path d="M1.33334 1.33334L14.6667 14.6667" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
			<path d="M1.3161 14.6264L14.6667 1.33333" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
		</svg></button>
	<!--v-if-->
	</form>
	</template>
	
	<script>
	import { connectSearchBox } from "instantsearch.js/es/connectors";
	import { createWidgetMixin } from "vue-instantsearch/vue3/es";
	
	export default {
	mixins: [createWidgetMixin({ connector: connectSearchBox })],
	props: {
		delay: {
		type: Number,
		default: 200,
		required: false,
		},
	},
	data() {
		return {
		timerId: null,
		localQuery: "",
		};
	},
	unmounted() {
		if (this.timerId) {
		clearTimeout(this.timerId);
		}
	},
	computed: {
		query: {
		get() {
			return this.localQuery;
		},
		set(val) {
			this.localQuery = val;
			if (this.timerId) {
			clearTimeout(this.timerId);
			}
			this.timerId = setTimeout(() => {
			this.state.refine(this.localQuery);
			}, this.delay);
		},
		},
	},
	};
</script>
<style lang="scss">
.ais-SearchBox-form {
	margin-bottom: 20px;
	margin-top: 4px;
	padding: 0 0 8px 230px;
	position: fixed;
	z-index: 1;
	height: 4rem;
  backdrop-filter: blur(10px);
  background: radial-gradient(ellipse at 50% 100%, rgba(34,33,33,0) 0%, rgba(34,33,33,1) 80%);
	display: flex;
	width: calc(90vw - 230px);
	flex-direction: row;
	.search-icon {
			position: absolute;
			top: 20px;
			left: 260px;
		}
	
	input {
		flex-grow: 1;
		height: 48px;
		min-width: 130px;
		border-radius: 120px;
		padding: 4px 16px 4px 3rem;
		font-size: medium;
		margin: 8px 16px 4px 20px;
		background: $background;
		border: 1px solid $main-font-color;
		color: $main-font-color;
		&:active, &:focus-visible {
			outline: 2px solid $color-info-500;
		}
	}
}

@media (max-width: 713px) {
  .ais-SearchBox-form {
		padding-left: 0;
		padding-right: 0;
		width: 80vw;
		padding-bottom: 2rem;
		.search-icon {
			left: 1rem;
			top: 1.4rem;
		}
		input {
			margin-left: 0;
			margin-right: 0;
			height: 56px;
		}
  }
}
</style>