<template>
  <span class="tag" :style="getColor">
    {{ label }}
  </span>
</template>
<script>
import colorsMap from '@/services/colorsMap.json'

export default {
  name: "individualTag",
  props: {
    label: {
      type: String,
      required: true
    },
    defaultColor: {
      type: String
    }
  },
  computed: {
    getColor () {
      return colorsMap[this.label] || `background-color: ${this.defaultColor};`
    }
  }
}
</script>
<style lang="scss" scoped>
.tag {
  padding: 0 8px;
  margin: 2px;
  color: $background;
  border-radius: 8px;
  white-space: nowrap;
}
</style>