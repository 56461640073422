import { createStore } from 'vuex'

export default createStore({
  state: {
    selectedName: {}
  },
  getters: {
  },
  mutations: {
    SET_SELECTED_NAME (state, name) {
      state.selectedName = name
    },
    CLEAR_SELECTED_NAME(state) {
      state.selectedName = {}
    }
  },
  actions: {
  },
  modules: {
  }
})
