<template>
	<div class="container_name">
		<div class="row_main">
			<div class="block_image round_block">
				<img
				:src="nameObject.image"
				id="main_image"
				:alt="`representaiton of what the name ${nameObject.name} looks and feel like through synesthesia`"
				class="main_image"
				@load="getPalette(nameObject.image)"/>
			</div>
			<div class="block_description round_block" :style="getBlocksBackground">
				<h1 class="name-title">{{nameObject.name}}</h1>
				<div class="block_tags">
					<individualTag v-for="tag in nameObject.tags" :key="tag" :label="tag" :defaultColor="palette.LightVibrant"/>
				</div>
				<div class="block_tags">
					<individualTag v-for="couleur in nameObject.couleurs" :key="couleur" :label="couleur"/>
				</div>
				<div class="extra-link">
					<a :href="`https://www.behindthename.com/name/${nameObject.name}`" target="_blank">En savoir plus sur ce prénom</a>
					<img src="@/assets/icon-arrowsquareright.svg"/>
				</div>
			</div>
		</div>	
		<div class="row-redbubble" v-if="nameObject.redbubble && nameObject.redbubbleimages">
			<div class="block_redbubble round_block" :style="getBlocksBackground">
				<h1>Impressions disponibles !</h1>
				<p>Vous aimez ce prénom? Retrouvez le sur une variété d’objets allant du mug au sticker en passant par les vêtements et accessoires de bureau!</p>
				<p>Gâteau sur la banane: quand vous commandez mes créations sur Redbubble, je reçois un peu d’argent. Grâce à vous je peux continuer à créer, merci ❤️</p>
				<button class="btn-main outline" @click="openRedbubble()">
					Voir les objets disponibles
					<img src="@/assets/icon-arrowsquareright.svg"/>
				</button>
			</div>
			<div class="img_redbubble round_block" :style="`background-image: url(${nameObject.redbubbleimages[0]});`">
			</div>
			<div class="img_redbubble round_block" :style="`background-image: url(${nameObject.redbubbleimages[1]});`">
			</div>
		</div>
	</div>
</template>
<script>
import { mapState } from 'vuex';
import { getDocument } from '@/services/apiCalls'
import individualTag from '@/components/tag.vue'
import Vibrant from 'node-vibrant'

export default {
	components:{
		individualTag
	},
	data() {
		return {
			nameObject: {},
			id: "",
			palette: {}
		};
	},
	async mounted(){
		this.nameObject = await getDocument(this.$route.query.id)
	},
	computed: {
		...mapState([
		'selectedName'
		]),
		getBlocksBackground () {
			return `background: linear-gradient(180deg, ${this.palette.Muted} 0%, ${this.palette.DarkMuted} 100%);`
		}
	},
	created() {
		document.title = `Synesthes-AI - ${this.$route.params.name}`
		document.description = `Oeuvre représentant le ressenti du prénom ${this.$route.params.name}`
	},
	methods: {
		async getPalette(img) {
			const vibrant = new Vibrant( img )
			const palette = await vibrant.getPalette()
			this.palette.DarkMuted = palette.DarkMuted.getHex()
			this.palette.Muted = palette.Muted.getHex()
			this.palette.LightVibrant = palette.LightVibrant.getHex()
		},
		openRedbubble() {
      window.open(this.nameObject.redbubble, "_blank")
    },
	}
}
</script>
<style scoped lang="scss">
.round_block {
	border-radius: 24px;
	overflow: hidden;
}
.container_name {
	width: calc(100vw - 4rem);
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	padding: 0 2rem;
	gap: 2rem;
	.row_main {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-evenly;
		gap: 1rem;
		h1 {
			text-transform: capitalize;
			font-size: 2.3rem;
		}
		.block_image {
			flex-basis: calc(75% - 2rem);
			flex-grow: 1;
			.main_image {
				width:100%;
				height: 100%;
			}
		}
		.block_description {
			flex-basis: calc(25% - 5rem);
			background-color: $background-dark;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
			padding: 1.5rem;
			gap: 2rem;
			position: relative;
			min-width: 250px;
			flex-grow:1;
			min-height: 300px;

			.block_tags{
				text-align: left;
				line-height: 1.8rem;
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
			}
			.extra-link {
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				align-items: center;
				gap: 8px;
				position: absolute;
				bottom: 1.5rem;
				text-decoration: underline;
				text-underline-offset: 4px;
				a {
					font-weight: normal;
				}
			}
		}
	}
	.row-redbubble {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-evenly;
		gap: 1rem;
		.block_redbubble {
			flex-basis: calc(60% - 5rem);
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
			text-align: left;
			padding: 1.5rem;
			flex-grow: 1;
			h1 {
				margin-bottom: 1rem;
				text-align: left;
			}
			p {
				margin: 0.3rem;
			}
			button {
				margin-top: 1.5rem;
				&:hover {
					background: radial-gradient(97.57% 218.75% at 0.9% 2.98%, rgba(224, 224, 224, 0.2) 30%, rgba(255, 255, 255, 0) 100%);
					transition: all 0.3s ease-in-out;
				}
			}
		}
		.img_redbubble{
			flex-basis: calc(20% - 2rem);
			background-size: cover;
			background-position: center;
			min-width: 300px;
			flex-grow: 1;
		}
	}
}

@media (max-width: 713px) {
	.row-redbubble {
		flex-direction: column;
		.img_redbubble{
			min-height: 90vw;
		}
	}
}
</style>