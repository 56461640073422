import axios from 'axios';

async function getDocument(id) {
  const results = await axios.get(`/indexes/synesthesai/documents/${id}`)
  return results.data
}

export {
  getDocument
}
