<template>
<div class="container">
	<ais-instant-search
		:search-client="searchClient"
		index-name="synesthesai"
	>
	<div class="search-panel__filters hidden-mobile no-scroll">
		<div class="filters-section">
			<h2>Couleurs</h2>
			<ais-refinement-list show-more attribute="couleurs" operator="and" limit=8 />
		</div>
		<div class="filters-section">
			<h2>Mots-clés</h2>
			<ais-refinement-list show-more attribute="tags" operator="and" limit=8 />
		</div>
	</div>
		<search-box :delay="10" class="ais-SearchBox-input" />
		<div class="search-panel__results">
			<ais-state-results>
			<template v-slot="{ results: { hits, query } }">
				<ais-hits v-if="hits.length > 0">
					<template v-slot:item="{ item }">
						<router-link
							:to="{ path: `/name/${item.name}`, query: {id: item.id}}"
							@click="this.SET_SELECTED_NAME(item)">
							<div class="hit-name">
								<ais-highlight :hit="item" attribute="name" />
							</div>
							<img :src="item.thumbnail" align="left" :alt="`image of the feeling of the name ${item.name}, made with midjourney AI`" />
						</router-link>
					</template>
				</ais-hits>
				<ais-pagination v-if="hits.length > 0"/>
				<div v-else class="no-results">
					<p>Aucun résultat trouvé pour <b>{{ query }}</b>.</p>
					<p>Votre nom n'est pas dans la liste? vous pouvez faire une demande ici:</p>
					<button class="btn-main large">Demander un prénom</button>
				</div>
			</template>
			</ais-state-results>
			<ais-configure
				:attributesToSnippet="['description:50']"
				snippetEllipsisText="…"
			/>
	</div>
	</ais-instant-search>
</div>
</template>

<script>
import { instantMeiliSearch } from "@meilisearch/instant-meilisearch";
import { mapMutations } from "vuex";
import SearchBox from "./SearchBox";

export default {
components: {
	SearchBox,
},
data() {
	return {
		searchClient: instantMeiliSearch(
			process.env.VUE_APP_MEILI_HTTP_ADDR,
			process.env.VUE_APP_MEILI_API_KEY,
			{
				paginationTotalHits: 300,
				finitePagination: true,
			}
		),
	}
},
mounted() {
	console.log(process.env.VUE_APP_MEILI_HTTP_ADDR)
},
methods: {
	...mapMutations([
		'SET_SELECTED_NAME'
	])
}
};
</script>
<style lang="scss">
// global //
body,
h1 {
	margin: 0;
	padding: 0;
}

.container {
	padding: 0 1rem 1rem 1rem;
	display: flex;
	justify-content: center;
}

.no-scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.no-scroll::-webkit-scrollbar {
  display: none; /* Chrome, Safari and Opera */
}

.ais-InstantSearch {
	width: 90vw;
	overflow: hidden;
	margin: -1rem 0 0 0;
	display: flex;
	flex-direction: row;
}

// Liste //
.search-panel__results {
	margin-left: 15rem;
	margin-top: 4rem;
}

.ais-Hits-item {
	min-width: 200px;
	margin-bottom: 1em;
	width: calc(50% - 1rem);
	margin: 0.5rem;
	position: relative;
	box-shadow: rgba(2, 20, 38, 0.2) 0px 8px 24px;
	border-radius: 1.5rem;
	overflow: hidden;
	&:hover {
		a {
			color: white;
		}
		.hit-name {
			margin-bottom: 0.7rem;
			transition: all 0.4 ease-in-out;
		}
	}
}

.ais-Hits-list {
	display: flex;
	flex-wrap: wrap;
}

.no-results {
	width:80vw;
	height: 200px;
	height:auto;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	button {
		width:fit-content;
	}
}

.ais-Hits-item img {
	margin-right: 1em;
	width: 100%;
	height: 100%;
	margin-bottom: 0.5em;
	border-radius: 4px;
}

.ais-Highlight-highlighted {
	background: $color-info-400;
	font-style: normal;
}

.hit-name {
	margin-bottom: 0.5em;
	margin-left:0.5em;
	position: absolute;
	bottom: 0;
	padding: 8px 16px;
	font-weight: 400;
	text-transform: capitalize;
	background: radial-gradient(97.57% 210.75% at 0.9% 2.98%, rgba(132, 132, 132, 0.4) 0%, rgba(255, 255, 255, 0) 100%);
	border: 0.2px solid rgba(234, 234, 234, 0.3);
	box-shadow: inset -2px 2px 3px rgba(255, 255, 255, 0.1);
	filter: drop-shadow(0px 2px 6px rgba(29,24,24,0.1));
	backdrop-filter: blur(4px);
	border-radius: 120px;
}

.hit-info {
	font-size: 90%;
}

.header {
	display: flex;
	align-items: center;
	min-height: 50px;
	padding: 0.5rem 1rem;
	background-image: linear-gradient(to right, #4dba87, #2f9088);
	color: #fff;
	margin-bottom: 1rem;
}

.header-title {
	font-size: 1.2rem;
	font-weight: normal;
}

.hit-description {
	font-size: 90%;
	margin-bottom: 0.5em;
	color: grey;
}

.header-title::after {
	content: " ▸ ";
	padding: 0 0.5rem;
}

.header-subtitle {
	font-size: 1.2rem;
}

// Filtres //
.search-panel__filters {
	width: 220px;
	position: fixed;
	z-index: 2;
	height: 90vh;
	overflow-y: scroll;

	.ais-SearchBox-input {
		height: 32px;
		width:80%;
	}
	.ais-RefinementList-count {
		margin-left: 4px;
		color: $color-info-500;
	}
}

.filters-section {
	padding: 18px 24px 22px;
	margin-top: 1rem;
	background: radial-gradient(97.57% 210.75% at 0.9% 2.98%, rgba(132, 132, 132, 0.08) 0%, rgba(255, 255, 255, 0) 100%);
	border: 0.3px solid rgba(234, 234, 234, 0.3);
	box-shadow: inset -2px 2px 3px rgba(255, 255, 255, 0.1);
	filter: drop-shadow(0px 2px 6px rgba(29, 24, 24, 0.1));
	backdrop-filter: blur(4px);
	border-radius: 1.8rem;
	h2 {
		text-align: left;
		width: 100%;
		font-size: 1.4rem;
	}
}

.ais-RefinementList-list {
	display:flex;
	flex-direction: column;
	align-items: flex-start;
}

.ais-RefinementList-label {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0px;
	gap: 4px;
	span {
		text-overflow: ellipsis;
		max-width: 70%;
		overflow: hidden;
		text-align: left;
		white-space: nowrap;
	}
	&:hover {
		span {
			font-weight: 700;
		}
	}
}

.ais-RefinementList-showMore {
	margin-top: 16px;
  white-space: nowrap;
  font-weight: 700;
	padding: 12px;
  text-decoration: none;
  cursor: pointer;
  background: radial-gradient(97.57% 218.75% at 0.9% 2.98%, rgba(0, 107, 137, 0.4) 0%, rgba(255, 255, 255, 0) 100%);
  border: 0.4px solid rgba(234, 234, 234, 0.3);
  box-shadow: inset -2px 2px 3px rgba(255, 255, 255, 0.1);
  filter: drop-shadow(0px 2px 6px rgba(29, 24, 24, 0.1));
  backdrop-filter: blur(4px);
  border-radius: 120px;
  color: white;
	&:hover {
    background: radial-gradient(97.57% 218.75% at 0.9% 2.98%, rgba(0, 107, 137, 0.5) 30%, rgba(255, 255, 255, 0) 100%);
    transition: all 0.3s ease-in-out;
  }
}

.ais-RefinementList-item {
	margin: 2px 0;
}

.ais-RefinementList-checkbox {
	height: 16px;
	width: 16px;
	margin-right: 8px;
	accent-color: $color-info-500;
}

// Searchbox //
.ais-SearchBox {
margin-bottom: 2rem;
}

// Pagination //
.ais-Pagination {
margin: 2rem auto;
	ul {
		justify-content: flex-end;
		text-align: center;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		li {
			margin-left: 8px;
			padding: 4px;
			width:24px;
			height: 24px;
			background-color: $background-dark;
		}
	}
}

// Mobile //
@media (max-width: 713px) {
	.search-panel__results {
		margin-left: 0;
		margin-top: 6rem;
	}
	.ais-InstantSearch {
		display: flex;
		justify-content: center;
	}
	.ais-Hits-list {
		justify-content: center;
	}
	.ais-Hits-item {
		width: calc(90% - 1rem);
	}
}
</style>


