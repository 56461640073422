import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/css/main.scss'
import InstantSearch from 'vue-instantsearch/vue3/es';
import axios from 'axios'

axios.defaults.baseURL = process.env.VUE_APP_MEILI_HTTP_ADDR
axios.defaults.headers.common['Authorization'] = `Bearer ${process.env.VUE_APP_MEILI_API_KEY}`

createApp(App)
	.use(store)
	.use(router)
	.use(InstantSearch)
	.mount('#app')
