<template>
  <nav>
    <router-link class="logo" to="/"><h1 class="logo">Synesthes-AI</h1></router-link>
    <button class="btn-main" id="hamburger-menu" @click="openMenu()">
      <img src="@/assets/hambergermenu.svg"/>
    </button>
    <div :class="['nav-menu', { isMobile, isOpen: menuIsOpen }]">
      <button v-if="menuIsOpen" class="btn-main" id="hamburger-menu" @click="openMenu()">
        <img :src="menuIsOpen ? '@/assets/icon-close.svg': '@/assets/hambergermenu.svg'"/>
      </button>
      <router-link to="/">Collection</router-link>
      <router-link to="/about">Infos</router-link>
      <button class="btn-main large" @click="openRequestForm()"> Demander un prénom </button>
    </div>
  </nav>
  <router-view class="main-container"/>
</template>
<script>

export default {
  data() {
    return {
      menuIsOpen: false,
      window: {
        height: 0,
        width: 0
      }
    };
  },
  computed: {
    isMobile() {
      return this.window.width < 830
    }
  },
  watch: {
    $route () {
      this.menuIsOpen = false
    }
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    openRequestForm() {
      window.open("https://forms.gle/ktnDQw2HruWrSj8d8", "_blank")
    },
    openMenu() {
      this.menuIsOpen = !this.menuIsOpen
    }
  }
};
</script>
<style lang="scss">
#app {
  font-family: 'Raleway', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  padding-top: 7rem;
}

nav {
  padding: 16px 5rem;
  display: flex;
  align-items:center;
  justify-content: space-between;
  flex-wrap: wrap;
  position: fixed;
  top: 0;
  width: calc(100vw - 10rem);
  z-index: 2;
  background: $background;
  backdrop-filter: blur(10px);

  a {
    margin:16px 16px;
    &.logo {
      margin: 0;
    }
  }

  button {
    margin-left:16px;
  }

  h1 {
    font-size: 2.4rem;
    line-height: 4.5rem;
    white-space: nowrap;
  }
}

#hamburger-menu {
  width: 48px;
  height: 48px;
  display: none;
  padding: 2px;
}

.nav-menu {
  display: flex;
  flex-direction: row;
  width:fit-content;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  z-index: 3;
  &.isMobile {
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 80vw;
    z-index: 10;
    align-items: center;
    justify-content: flex-start;
    padding: 2rem;
    background: radial-gradient(97.57% 210.75% at 0.9% 2.98%, rgba(45, 45, 45, 0.9) 0%, rgba(13, 13, 13, 1) 100%);
    flex-direction: column;
    border: 1px solid #EAEAEA4D;
    box-shadow: 0px 2px 6px 0px #1D18181A;
    box-shadow: -2px 2px 3px 0px #FFFFFF1A inset;
    filter: drop-shadow(0px 2px 6px rgba(29, 24, 24, 0.1));
    backdrop-filter: blur(2px);
    display: none;
    gap: 48px;
    button {
      margin-left: 0;
      &#hamburger-menu {
        align-self: flex-end;
        margin-right: 3rem;
      }
    }
    &.isOpen {
      display: flex;
    }
  }
}

@media (max-width: 450px) {
  nav {
    font-size: 1em;
    justify-content: space-between;
    padding: 1rem 2rem;
    width: calc(100vw - 4rem);
    a {
      margin: 16px 8px;
    }
    .nav-menu.isMobile{
      button {
        &#hamburger-menu {
          margin-right: 0.6rem;
        }
      }
    }
  }
  nav button {
     margin-left: 0;
  }
}

@media (max-width: 400px) {
  #app {
    padding-top: 9rem;
  }
}

@media (max-width: 830px) {
  #hamburger-menu {
    display: block;
  }
}

@media (max-width: 713px) {
  nav .logo {
    font-size: 2rem;
  }	
  .nav-menu {
    justify-content: center;
  }
}

@media (max-width: 350px) {
  nav .logo {
    font-size: 1.4rem;
  }	
}
</style>
